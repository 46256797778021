import React from "react";
import { MdOutlineReadMore } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const NewsCard = ({ news }) => {
  const nav = useNavigate();

  return (
    <>
      {news.map((items, index) => (
        <div
          key={index}
          onClick={()=>nav(`/newsdetails/${items.id}`)}
          className="cursor-pointer space-y-4  bg-white rounded-lg transition-transform duration-300 hover:scale-95"
        >
          {/* Image Section */}
          <div className="w-full h-60 sm:h-72 bg-gray-300 rounded-lg overflow-hidden">
            <img
              src={items.image[1].img}
              alt={items.headline}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Content Section */}
          <div className="flex flex-col gap-2">
            <h1 className="text-xs sm:text-sm font-bold uppercase text-blue-500">
             {items.Date}
            </h1>
            <h4 className="font-bold text-lg sm:text-xl">{items.Title}</h4>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsCard;
