import React from "react";
import SimpleSlider from "../../../Containers/SimpleSlider";
import logo1 from "../../../Assets/PartnerLogo/1. Huawei Technologies logo.png";
import logo2 from "../../../Assets/PartnerLogo/2. Dodai.jpg";
import logo3 from "../../../Assets/PartnerLogo/3. Mogzit In-home care logo.png";
import logo4 from "../../../Assets/PartnerLogo/4. Yenehealth logo.jpg";
import logo5 from "../../../Assets/PartnerLogo/5. American heart association.png";
import logo6 from '../../../Assets/PartnerLogo/6. Ethiopian medical association.png'
import logo7 from '../../../Assets/PartnerLogo/7. Kidiy foundation.jpg'
import logo8 from '../../../Assets/PartnerLogo/8. Mesirat.png'
import logo9 from '../../../Assets/PartnerLogo/9. Orbit innovation hub.png'
import logo10 from '../../../Assets/PartnerLogo/10. Prana events.png'
import logo11 from '../../../Assets/PartnerLogo/11. Ethel events.jpg'
import logo12 from '../../../Assets/PartnerLogo/12. Temari care.png'
import logo13 from '../../../Assets/PartnerLogo/13. Wondfo Ethiopia.png'
import logo14 from '../../../Assets/PartnerLogo/14. Heal venture medical and surgical hospital.jpg'
import logo15 from '../../../Assets/PartnerLogo/15. Cardiac Center Ethiopia logo.jpg'
import logo16 from '../../../Assets/PartnerLogo/16. Fhi 360.jpg'
import logo17 from '../../../Assets/PartnerLogo/17. A2SV.jpg'
import logo18 from '../../../Assets/PartnerLogo/18. TILET TECH.jpg'
import logo19 from '../../../Assets/PartnerLogo/19. SUMMER MEDIA.png'
import logo20 from '../../../Assets/PartnerLogo/20. Deborha foundation.png'
import logo21 from '../../../Assets/PartnerLogo/21. Ithiel MCH Center logo.jpg'
import logo22 from '../../../Assets/PartnerLogo/lenat.jpeg'

const OurPartner = () => {
    const images = [
      { img: logo1 },
      { img: logo2 },
      { img: logo3 },
      { img: logo4 },
      { img: logo5 },
      { img: logo6 },
      { img: logo7 },
      // { img: logo8 },
      { img: logo9 },
      { img: logo10 },
      { img: logo11 },
      { img: logo12 },
      { img: logo13 },
      { img: logo14 },
      { img: logo15 },
      // { img: logo16 },
      { img: logo17 },
      { img: logo18 },
      { img: logo19 },
      { img: logo20 },
      { img: logo21 },
      {img:logo22}
      // Add more image URLs here
    ];
  return (
    <div className="md:p-12 p-4">
      <div className="flex flex-col justify-center items-center pb-4">
        <h1 className="md:text-3xl">Our Partners</h1>


        <div className="divider" />
      </div>
      <SimpleSlider images={images}/>
    </div>
  );
};

export default OurPartner;
