import React, { useEffect } from "react";
import derash from "../../Assets/image/Derash.png";
import Sectioncards from "../../Component/ServiceDetail/Digitalhealth/Sectioncards";
import { FaCloudDownloadAlt } from "react-icons/fa";

const DigitalHealth = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center gap-10">
      {/* Hero Section */}
      <div className="relative h-[30rem] sm:h-80 md:h-96 w-full flex flex-col justify-center items-center">
        <img
          src={derash}
          alt="Digital Health"
          className="h-full w-full object-cover object-top"
        />
        <div className="absolute inset-0 bg-black bg-opacity-70 text-white flex flex-col justify-center items-center px-4 sm:px-6 lg:px-12">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center">
            Digital Health
          </h1>
          <div className="w-16 h-1 bg-secondary mt-2"></div>
          <p className="text-center w-full sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-sm sm:text-base md:text-lg lg:text-xl py-3 sm:py-4 md:py-5">
            At Blue Health Ethiopia, we are transforming healthcare through
            innovative digital solutions that address critical gaps in emergency
            response and healthcare training. Our flagship digital health
            initiative, the Derash mobile application, is designed to empower
            individuals and communities with essential tools and knowledge to
            manage emergencies effectively.
          </p>
        </div>
      </div>

      {/* Section Cards */}
      <Sectioncards />

      {/* Why Derash Matters Section */}
      <div className="flex flex-col justify-center items-center px-4 sm:px-6 lg:px-12 pb-10">
        <h1 className="text-2xl sm:text-3xl md:text-4xl text-center">
          Why Derash Matters
        </h1>
        <div className="w-16 h-1 bg-secondary mt-2"></div>
        <p className="text-center w-full sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-sm sm:text-base md:text-lg lg:text-xl py-3 sm:py-4 md:py-5">
          Ethiopia faces significant challenges in emergency response, with
          delays in accessing timely care often leading to preventable
          fatalities. The Derash app addresses these issues by:
        </p>
        <ul className="text-sm sm:text-base md:text-lg lg:text-xl list-disc list-inside space-y-3 px-4">
          <li>Reducing response times during emergencies.</li>
          <li>
            Empowering individuals with knowledge to act effectively in critical
            situations.
          </li>
          <li>
            Connecting users with essential emergency services and healthcare
            facilities.
          </li>
        </ul>
      </div>

      {/* Download Button */}
      <a href="https://play.google.com/store/apps/details?id=com.bluehealthethiopia.derash&pcampaignid=web_share" className="bg-secondary md:px-6 py-3 font-bold text-lg sm:text-xl md:text-2xl flex items-center justify-center gap-2 rounded-lg hover:bg-primary hover:text-white transition-all duration-300 ease-in-out w-56 sm:w-auto">
        <FaCloudDownloadAlt /> Download App
      </a>
    </div>
  );
};

export default DigitalHealth;
