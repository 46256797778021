import React from "react";
import logoblue from "../../../Assets/logoblue.png";

const BlueDesc = () => {
  return (
    <div className="bg-blue flex flex-col justify-end items-center relative md:p-12 px-4 py-12 md:pt-44">
      <img src={logoblue} alt="" className="w-64 absolute left-0" />
      <div className="p-12 flex flex-col justify-center items-center z-50">
        <h1 className="md:text-3xl">Blue Health Ethiopia</h1>
        <div className="divider" />
        <p className="text-center md:w-3/4 w-screen md:p-5 p-4 md:text-lg text-sm">
          Blue Health Ethiopia is a trailblazing healthcare innovation company
          dedicated to enhancing emergency response, professional healthcare
          education, and community health outcomes in Ethiopia. Through our
          flagship solutions, such as the Derash mobile app and an advanced
          Continuous Professional Development (CPD) platform, we address
          critical gaps in emergency care, healthcare training, and knowledge
          dissemination. Our research-driven approach and strategic partnerships
          ensure we remain at the forefront of healthcare innovation, empowering
          professionals and communities alike to save lives and create a
          healthier, more resilient society.
        </p>
      </div>
      {/* <Progress/> */}
    </div>
  );
};

export default BlueDesc;
