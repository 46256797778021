import React from "react";

const Sectioncards = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center px-6 sm:px-10 gap-5 w-full">
      {/* Card 1 */}
      <div className="bg-blue rounded-lg px-6 sm:px-10 py-6 text-base sm:text-lg space-y-4 w-full md:w-1/2 h-auto flex flex-col justify-center items-center shadow-lg">
        <h1 className="text-xl sm:text-2xl font-semibold text-center">Derash: Your Lifesaving Companion</h1>
        <p className="text-center sm:text-left">
          The Derash app is a multilingual mobile application tailored to improve emergency response times and equip 
          users with life-saving knowledge. It bridges the gap between emergencies and timely medical intervention by 
          providing critical features, including:
        </p>
        <ul className="space-y-3 text-left w-full">
          <li><span className="font-bold">🚑 Ambulance Connections:</span> Locate and contact nearby ambulances for rapid emergency response.</li>
          <li><span className="font-bold">🏥 Hospital Locator:</span> Access a directory of nearby hospitals with contact details and directions.</li>
          <li><span className="font-bold">🩹 First Aid Guidance:</span> Step-by-step instructions on managing various emergency situations, tailored for both victims and bystanders.</li>
        </ul>
      </div>

      {/* Card 2 */}
      <div className="bg-blue rounded-lg px-6 sm:px-10 py-6 text-base sm:text-lg space-y-4 w-full md:w-1/2 h-auto flex flex-col justify-center items-center shadow-lg">
        <h1 className="text-xl sm:text-2xl font-semibold text-center">Multilingual Accessibility</h1>
        <p className="text-center sm:text-left">
          Derash is available in English, Amharic, and Afan Oromo, covering some of the most widely spoken languages 
          in Ethiopia. This ensures that users from diverse regions can access the app's resources in their preferred 
          language. We plan to expand to include more local languages, increasing accessibility nationwide.
        </p>
        <h1 className="text-xl sm:text-2xl font-semibold text-center">AI Chatbot: 24/7 First Aid Assistant</h1>
        <p className="text-center sm:text-left">
          An intelligent chatbot that provides real-time first aid guidance for various medical emergencies, helping 
          users quickly assess situations and take appropriate action before professional help arrives.
        </p>
      </div>
    </div>
  );
};

export default Sectioncards;
