import React, { useEffect } from 'react'
import DescConsult from '../../Component/ServiceDetail/Consultation/DescConsult'

const Consultation = () => {
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <DescConsult/>
    </div>
  )
}

export default Consultation
