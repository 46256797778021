import React, { useEffect } from "react";
import { PiTelevisionBold, PiTargetBold } from "react-icons/pi";
import { SlDiamond } from "react-icons/sl";
import Slider from "react-slick";
import DescCard from "../../../Containers/DescCard";
import { Link } from "react-router-dom";

const Home = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const items = [
    {
      icon: PiTargetBold,
      text: "Bridging gaps in emergency care, healthcare training, and community awareness in Ethiopia through technology, education, research, partnerships, and empowerment to save lives and enhance health outcomes.",
    },
    {
      icon: PiTelevisionBold,
      text: "To lead the transformation of healthcare in Ethiopia and sub-Saharan Africa through technology, innovation, and education.",
    },
    {
      icon: SlDiamond,
      text: "Empowerment, Collaboration, Excellence, Community Focus, Professionalism, Commitment to Integrity, and Innovation",
    },
  ];
  const slide = [
    {
      img: "firstaid",
      header: "Blue Health First Aid AHA and BLS Training",
      text: "Our training equips individuals with essential life-saving skills to respond effectively during emergencies, offering internationally recognized certification from the American Heart Association (AHA) to ensure global standards in emergency care.",
      btn: "Register Now",
    },
    // {
    //   img: "virtual",
    //   header: "Blue Health Virtual Seminar",
    //   text: "A platform that allows health professionals to discuss current management updates of different health topics FOR BETTER PATIENT CARE",
    //   btn: "Sign In",
    //   link: "/signin",
    // },
    {
      img: "cpd",
      header: "Blue Health Ethiopia's CPD training",
      text: "Blue Health Ethiopia provides accredited CPD training for healthcare professionals, delivered through online, in-person, and hybrid formats, ensuring flexibility while enhancing critical skills essential for professional growth and improved patient care.",
      btn: "Register Now",
      link:'https://cpd.bluehealthethiopia.com/'
    },
    {
      img: "derash",
      header: "Derash(ደራሽ)2.0",
      text: "Derash is a multilingual app aimed at reducing emergency response times and saving lives through quick access to first aid guidance and nearby emergency services.",
      btn: "Download App",
      link: "https://play.google.com/store/apps/details?id=com.bluehealthethiopia.derash&pcampaignid=web_share",
    },
  ];
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div id="Home" className="top-0 overflow-hidden">
      <Slider {...settings}>
        {slide.map((items) => (
          <div
            className={`${
              "bg-" + items.img
            } bg-cover bg-center h-fit text-white`}
          >
            <div className="bg-black md:px-12 px-5 py-32 bg-opacity-50 w-full md:h-screen h-fit flex flex-row justify-start items-center ">
              <div className="">
                <h1 className="md:text-5xl text-2xl md:w-3/5  py-1">
                  {items.header}
                </h1>
                <div className="divider" />
                <p className="md:w-3/6 py-3 md:text-xl mb-4">{items.text}</p>
                <Link
                  to={items.link}
                  className="border-2 border-secondary md:py-2 py-1 md:px-12 px-6 text-sm md:text-lg font-bold hover:border-primary duration-70 ease-in-out"
                >
                  {items.btn}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="px-12 flex md:flex-row flex-col justify-between md:absolute items-center p-2 w-full gap-32 md:gap-0">
        <DescCard header={"Mission"} items={items[0]} />
        <DescCard header={"Vision"} items={items[1]} />
        <DescCard header={"Value"} items={items[2]} />
      </div>
    </div>
  );
};

export default Home;
