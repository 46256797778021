import React from "react";
import Slider from "react-slick";
import image1 from "../../../Assets/Research/photo_2025-02-11_09-37-10.jpg";
import image2 from "../../../Assets/Research/photo_2025-02-11_09-37-59.jpg";
import image3 from "../../../Assets/Research/photo_2025-02-11_09-38-05.jpg";
import image4 from "../../../Assets/Research/photo_2025-02-11_09-38-10.jpg";

const ImageSlide = () => {
  const settings = {
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 5000, // Auto-play speed
    arrows: false, // Hide default arrows
    dots: true, // Show dots for navigation
    responsive: [
      {
        breakpoint: 1024, // Tablet
        settings: {
          dots: true, // Show dots on tablets
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          dots: true, // Show dots on mobile
        },
      },
    ],
  };

  const images = [{img:image1}, {img:image2}, {img:image3}, {img:image4}];

  return (
    <div className="w-full">
      {/* Image Slider */}
      <div className="overflow-hidden">
        <Slider {...settings}>
          {images.map((items, index) => (
           <div key={index} className="h-[20rem] sm:h-[25rem] md:h-[35rem] outline-none">
           <img
             src={items.img}
             alt={`Research ${index + 1}`}
             className="w-full h-full object-cover object-top outline-none"
           />
         </div>
          ))}
        </Slider>
      </div>

      {/* Text Section */}
      <div className="flex flex-col justify-center items-center px-4 md:px-10 lg:px-20 py-6 text-center">
        <h1 className="text-3xl md:text-5xl font-bold">Research</h1>
        <div className="w-20 h-1 bg-secondary my-3 rounded-full"></div>
        <p className="text-base md:text-lg lg:w-3/5 py-5 leading-relaxed">
          At Blue Health Ethiopia, we are driving innovative research
          initiatives to transform healthcare delivery and improve well-being
          across Ethiopia. With a strong focus on preventive medicine,
          pre-hospital care, and hospital care, our research services aim to
          address critical healthcare challenges, advance medical knowledge, and
          develop sustainable solutions that benefit communities nationwide.
        </p>
      </div>
    </div>
  );
};

export default ImageSlide;
