import React from "react";
import Slider from "react-slick";

const Slide = ({ blogslide }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {blogslide.map((items, index) => (
          <div key={index} className="relative h-screen w-full">
            <img
              src={items.image}
              alt=""
              className="h-full w-full object-cover object-center"
            />
            <div className="absolute inset-0 sm:w-4/5 md:w-2/5 bg-white rounded-lg flex flex-col justify-center items-start sm:px-6 md:px-12 m-4 md:m-12 gap-4 sm:gap-5 p-6 sm:p-8">
              <h1 className="text-lg sm:text-xl md:text-2xl font-bold">{items.Title}</h1>
              <p className="text-sm sm:text-base md:text-lg">
                {items.Desc.slice(0, 300)}...
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slide;
