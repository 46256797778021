import React from "react";
import TemplateSlider from "../../../Containers/CardTemplateSlider";
import vector1 from "../../../Assets/Vector/HealthApp..jpg";
import vector2 from "../../../Assets/Vector/Research..jpg";
import vector3 from "../../../Assets/Vector/Consultation (2).jpg";
import vector4 from "../../../Assets/Vector/Training..jpg";

const Services = () => {
  const data = [
    {
      id: 1,
      header: "Health App",
      text: "By developing mobile and web applications we aim to equip our community with basic health related knowledge",
      img: vector1,
      route: "/digitalhealth",
    },
    {
      id: 2,
      header: "Training",
      text: "Through the training we give, we aim to equip our community with the knowledge of different essential health issues",
      img: vector4,
      route: "/training",
    },
    {
      id: 3,
      header: "Research",
      text: "We are working to improve the health problems of Ethiopia by knowing it's core problem through researches",
      img: vector2,
      route: "/research",
    },
    {
      id: 4,
      header: "Consultation",
      text: "At Blue Health Ethiopia, we provide comprehensive health consultation services tailored for businesses across various industries.",
      img: vector3,
      route: "/consultation",
    },
  ];
  return (
    <div id="Services" className="p-14">
      <div className="flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">Our Services</h1>
        <div className="divider" />
      </div>
      <TemplateSlider card={data} />
    </div>
  );
};

export default Services;
