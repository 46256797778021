import React, { useEffect, useState } from "react";
import icon2 from "../../../Assets/ServiceIcons/charity_3047752.png";
import icon6 from "../../../Assets/ServiceIcons/happy_11312219.png";
import icon7 from "../../../Assets/ServiceIcons/medical-bag_18350957.png";
import icon8 from "../../../Assets/ServiceIcons/medical-school_18254275.png";
import icon13 from "../../../Assets/ServiceIcons/public-relation_12450942.png";
import icon14 from "../../../Assets/ServiceIcons/response_18712749.png";
import icon15 from "../../../Assets/ServiceIcons/van_8337294.png";
import icon16 from "../../../Assets/ServiceIcons/worker-insurance_15540434.png";
import icon17 from "../../../Assets/ServiceIcons/workforce_16864433.png";

const ImpactSection = () => {
  const icons = [
    { icon: icon2, title: "Parents and Caregivers" },
    { icon: icon6, title: "Teachers and Students" },
    { icon: icon7, title: "Healthcare Professionals" },
    { icon: icon8, title: "Healthcare Students" },
    { icon: icon13, title: "General Public" },
    { icon: icon14, title: "Employees in High-Risk Industries" },
    { icon: icon15, title: "Transport Service Providers" },
    { icon: icon16, title: "Security and Safety Personnel" },
    { icon: icon17, title: "Office Employees" },
  ];

  const targetCounters = {
    professionalsTrained: 15000,
    individualsCertified: 13000,
  };

  const [impactCounters, setImpactCounters] = useState({
    professionalsTrained: 0,
    individualsCertified: 0,
  });

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animateCounter = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / 2000; // 2 seconds animation

      if (progress >= 1) {
        setImpactCounters(targetCounters); // Ensure final value is set
        return;
      }

      setImpactCounters({
        professionalsTrained: Math.floor(progress * targetCounters.professionalsTrained),
        individualsCertified: Math.floor(progress * targetCounters.individualsCertified),
      });

      animationFrame = requestAnimationFrame(animateCounter);
    };

    animationFrame = requestAnimationFrame(animateCounter);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="bg-gray-100 py-10 px-4 md:px-10">
      <div className="flex flex-col justify-center items-center gap-8">
        
        {/* Who Can Benefit Section */}
        <div className="text-center">
          <h1 className="text-3xl font-semibold">Who Can Benefit?</h1>
          <div className="divider w-16 bg-gray-500 h-1 my-3 mx-auto"></div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 py-5">
            {icons.map((item, index) => (
              <div key={index} className="flex flex-col items-center gap-2">
                <img src={item.icon} alt={item.title} className="w-16 sm:w-24 md:w-28" />
                <p className="text-center text-sm sm:text-base">{item.title}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Flexible Learning Section */}
        <div className="text-center max-w-3xl">
          <h1 className="text-3xl font-semibold">Flexible Learning</h1>
          <div className="divider w-16 bg-gray-500 h-1 my-3 mx-auto"></div>
          <p className="text-gray-700 text-lg py-4">
            We offer in-person, online, and hybrid learning formats, including
            weekly virtual seminars, self-paced courses, and interactive sessions.
          </p>
        </div>

        {/* Our Impact Section */}
        <div className="text-center max-w-3xl">
          <h1 className="text-3xl font-semibold">Our Impact</h1>
          <div className="divider w-16 bg-gray-500 h-1 my-3 mx-auto"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-5">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-3xl font-bold text-secondary">
                {impactCounters.professionalsTrained.toLocaleString()}+
              </h3>
              <p className="text-gray-700 mt-2">
                Healthcare professionals trained, improving medical care across Ethiopia.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-3xl font-bold text-secondary">
                {impactCounters.individualsCertified.toLocaleString()}+
              </h3>
              <p className="text-gray-700 mt-2">
                Individuals certified in first aid, strengthening community emergency response.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ImpactSection;
