import React from "react";

const MoreDesc = () => {
  return (
    <div className="flex flex-col justify-center items-center py-6 px-4 md:px-10 gap-10">
      {/* Institutional Ethics Review Board Section */}
      <div className="flex flex-col justify-center items-center w-full md:w-3/5 text-center">
        <h1 className="text-2xl md:text-3xl font-bold text-black">
          Institutional Ethics Review Board (IRB)
        </h1>
        <div className="w-20 h-1 bg-secondary my-3 rounded-full"></div>
        <p className="text-gray-700 text-base md:text-lg leading-relaxed">
          Blue Health Ethiopia operates an in-house Institutional Ethics Review
          Board (IRB) to ensure that all research activities meet ethical
          standards. The IRB evaluates and approves research proposals, ensuring
          compliance with national and international guidelines.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="flex flex-col justify-center items-center w-full md:w-3/4 text-center">
        <h1 className="text-2xl md:text-3xl font-bold text-black">
          Why Choose Us?
        </h1>
        <div className="w-20 h-1 bg-secondary my-3 rounded-full"></div>
        <ul className="text-left text-base md:text-lg space-y-4">
          <li className="flex gap-2">
            <span>
              {" "}
              <span className="font-bold">
                Innovative and Relevant Research:
              </span>
              Our projects focus on critical areas like emergency care, surgical
              practices, and pain management, addressing pressing healthcare
              needs in Ethiopia.
            </span>
          </li>
          <li className="flex gap-2">
            <span>
              {" "}
              <span className="font-bold">Community-Driven Approach: </span>
              We prioritize research that directly benefits Ethiopian
              communities, improving access to care and health outcomes.
            </span>
          </li>
          <li className="flex gap-2">
            <span>
              <span className="font-bold">Commitment to Excellence: </span>
              With a dedicated team and robust ethics oversight, we ensure the
              highest quality in every project.
            </span>
          </li>
          <li className="flex gap-2">
            <span>
              {" "}
              <span className="font-bold">Knowledge Sharing: </span>
              Our findings contribute to the global body of medical knowledge
              while supporting local healthcare professionals' growth and
              development.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MoreDesc;
