import React from "react";

const DescriptionSection = () => {
  const descriptionData = [
    {
      title: "Our Research Focus Areas",
      description:
        "Our research initiatives target key areas of healthcare improvement, including:",
      items: [
        {
          title: "Prehospital and Hospital Emergency Care",
          content:
            "Developing strategies to enhance emergency response systems and improve patient outcomes during the critical 'golden hour.'",
        },
        {
          title: "Surgical Care",
          content:
            "Exploring advancements in minimally invasive and robotic surgeries to address gaps in surgical practices across Ethiopia.",
        },
        {
          title: "Pain Management",
          content:
            "Investigating perioperative and post-operative pain management techniques to enhance patient care and recovery.",
        },
      ],
    },
  ];

  const descriptionData2 = [
    {
      title: "Ongoing Research Projects",
      description:
        "Our team is conducting impactful studies to improve healthcare in Ethiopia. Current research includes:",
      items: [
        {
          title: "First Aid Training for Students",
          content: "Evaluating how training improves emergency preparedness.",
        },
        {
          title: "Minimally Invasive Surgery in Ethiopia",
          content: "Assessing adoption and effectiveness.",
        },
        {
          title: "Hypertension & Diabetes in Addis Ababa",
          content: "Identifying key risk factors for prevention.",
        },
        {
          title: "Pediatric Pain Management",
          content: "Enhancing care for young surgical patients.",
        },
        {
          title: "ICU Infections & Antibiotic Resistance",
          content: "Studying microbial patterns for better treatment.",
        },
        {
          title: "Basic Life Support Training Impact",
          content:
            "Assessing effectiveness for law enforcement & bus drivers.",
        },
        {
          title: "Online CPD Training for Healthcare Professionals",
          content: "Understanding its effectiveness in Ethiopia.",
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-center items-center px-6 md:px-12 lg:px-20 py-10 gap-8">
      {[...descriptionData, ...descriptionData2].map((section, index) => (
        <div
          key={index}
          className="w-full md:w-1/2 bg-blue rounded-lg p-6 md:p-8 text-lg space-y-6 flex flex-col justify-center items-center shadow-lg"
        >
          <h1 className="text-2xl md:text-3xl font-bold text-black text-center">
            {section.title}
          </h1>
          <p className="text-base md:text-lg text-center">{section.description}</p>
          <ul className="space-y-3 text-left">
            {section.items.map((item, idx) => (
              <li key={idx} className="leading-relaxed">
                <span className="font-bold text-lg">{item.title}:</span> {item.content}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default DescriptionSection;
