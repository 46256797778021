import React from "react";
import { useNavigate } from "react-router-dom";

const TemplateSlider = ({ card }) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col md:flex-col justify-center items-center gap-5">
      {card.map((items, index) => (
        <div onClick={()=> navigate(`${items.route}`)} key={index} className={`flex ${index % 2 === 0 ?'flex-col md:flex-row':'flex-col md:flex-row-reverse'} justify-center items-center gap-7 w-full md:w-[50rem] cursor-pointer`}>
          <div className="">
            <img src={items.img} alt="" className="w-[50rem]"/>
          </div>
          <div className={`${index % 2 === 0 ?'text-center md:text-left':'text-center md:text-right'} `}>
            <h1 className="text-2xl font-bold">{items.header}</h1>
            <p className="text-gray-500">{items.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TemplateSlider;
