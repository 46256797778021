import React, { useEffect } from 'react'
import Imageslide from '../../Component/ServiceDetail/Research/Imageslide'
import Descriptionsection from '../../Component/ServiceDetail/Research/Descriptionsection'
import MoreDesc from '../../Component/ServiceDetail/Research/MoreDesc'

const Research = () => {
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <Imageslide/>
      <Descriptionsection/>
      <MoreDesc/>
    </div>
  )
}

export default Research
