import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../../Assets/Training/DSCF0957.jpg";
import img2 from "../../../Assets/Training/DSCF1111.jpg";
import img3 from "../../../Assets/Training/DSCF1059.jpg";
import img4 from "../../../Assets/Training/IMG_0099.JPG";
import img5 from "../../../Assets/Training/IMG_0102.JPG";
import img6 from "../../../Assets/Training/IMG_0105.JPG";

const Imageslide = () => {
  // react-slick settings
  const settings = {
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 5000, // Auto-play speed
    arrows: false, // Hide default arrows
    dots: true, // Show dots for navigation
    responsive: [
      {
        breakpoint: 1024, // Tablet
        settings: {
          dots: true, // Show dots on tablets
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          dots: true, // Show dots on mobile
        },
      },
    ],
  };

  const image = [
    { img: img1 },
    { img: img4 },
    { img: img6 },
    { img: img2 },
    { img: img5 },
    { img: img3 },
  ];

  return (
    <div className="overflow-hidden">
      {/* Slider Section */}
      <Slider {...settings}>
        {image.map((items, index) => (
          <div
            key={index}
            className="h-[20rem] sm:h-[25rem] md:h-[35rem] outline-none"
          >
            <img
              src={items.img}
              alt={`Training ${index + 1}`}
              className="w-full h-full object-cover object-top outline-none"
            />
          </div>
        ))}
      </Slider>

      {/* Training Description Section */}
      <div className="flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 py-6">
        <h1 className="text-3xl sm:text-4xl md:text-5xl text-center">
          Training
        </h1>
        <div className="divider"></div>
        <p className="text-center w-full sm:w-[30rem] md:w-[40rem] lg:w-[50rem] text-sm sm:text-base md:text-lg lg:text-xl py-3 sm:py-4 md:py-5">
          At Blue Health Ethiopia, we are committed to enhancing healthcare and
          community well-being through high-quality, impactful training
          programs. Our training services are designed to meet the diverse needs
          of both healthcare professionals and the general population,
          empowering individuals with the knowledge and skills to save lives and
          improve health outcomes.
        </p>
      </div>
    </div>
  );
};

export default Imageslide;
