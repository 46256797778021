import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import Logo from "../../Assets/Logo Transparent 1.png";
import LogoColor from "../../Assets/Logo Transparent 3.png";
import { BiMenuAltRight } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const PhoneNav = ({ sticky }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const Nav = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    // { link: "Medical Tourism", scroll: "Toursim" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];

  const handleNavigation = (section) => {
    setIsOpen(false); // Close the menu after selection
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => scrollToSection(section), 300); // Delay for page load
    } else {
      scrollToSection(section);
    }
  };

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      duration: 1000,
      offset: -100,
    });
  };

  return (
    <div
      className={`fixed overflow-hidden md:hidden w-full py-3 flex justify-between items-center z-[1111] px-4 ${
        sticky ? "bg-white" : "bg-white text-black"
      }`}
    >
      {/* Logo */}
      <ScrollLink
        to="Home"
        spy={true}
        smooth={true}
        offset={-100}
        duration={1000}
      >
        <img src={LogoColor} alt="Logo" className="w-36 cursor-pointer" />
      </ScrollLink>

      {/* Menu Icon */}
      <BiMenuAltRight
        onClick={() => setIsOpen(true)}
        className="text-4xl cursor-pointer"
      />

      {/* Navigation Drawer */}
      <div
        className={`fixed top-0 ${
          isOpen ? "right-0" : "-right-full"
        } h-screen w-64 ${
          sticky ? "bg-white text-black" : "bg-black text-white"
        } z-10 flex flex-col justify-between items-start shadow-lg transition-transform duration-300`}
      >
        <div className="">
          {/* Close Icon */}
          <IoMdClose
            onClick={() => setIsOpen(false)}
            className="m-4 text-4xl cursor-pointer"
          />

          {/* Navigation Links */}
          <div className="flex flex-col justify-start p-4 space-y-6 text-lg">
            {Nav.map((items, index) => (
              <span
                key={index}
                onClick={() => handleNavigation(items.scroll)}
                className="hover:underline hover:text-secondary p-1 duration-200 ease-in-out cursor-pointer"
              >
                {items.link}
              </span>
            ))}
          </div>
        </div>
        <button className="px-3 py-1 bg-secondary font-semibold text-white m-4 rounded-lg hover:bg-primary duration-150 ease-in-out">Verify Certification</button>
      </div>
    </div>
  );
};

export default PhoneNav;
