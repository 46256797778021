import React from "react";
import SimpleSlider from "../../../Containers/SimpleSlider";
import logo from '../../../Assets/Logo Transparent 3.png'
import award1 from "../../../Assets/Awards/1_Winner_of_Expand_North_Star_and_the_Ethiopian_Virtual_Roadshow.jpg";
import award2 from "../../../Assets/Awards/2.1 GLOBAL STARTUP AWARD HEALTH TECH STARTUP OF THE YEAR.jpg";
import award3 from "../../../Assets/Awards/2.2 GLOBAL STARTUP AWARD STARTUP OF THE YEAR.jpg";
import award4 from "../../../Assets/Awards/CPD Reacreditation 2025.jpg";
import award5 from "../../../Assets/Awards/3_Finalist_in_NINJA_Next_Innovation_with_Japan_organized_by_JICA.jpg";
import award6 from "../../../Assets/Awards/4. Point of Care Ultrasound in Trauma - EFAST Course.jpg";
import award7 from "../../../Assets/Awards/2. First National Health Exhibition_00.jpg";
import award8 from "../../../Assets/Awards/6. The Children's Heart Fund of Ethiopia Recognition_00.png";
import award9 from "../../../Assets/Awards/1_International_Training_Site_for_American_Heart_Association_00.png";
import award10 from "../../../Assets/Awards/5. African Impact Challenge 2024 Recognition_00.png";

const Awards = () => {
  const awards = [
    {
      img: award1,
      text: "Winner of Expand North Star and the Ethiopian Virtual Roadshow 2023",
    },
    {
      img: award2,
      text: "GLOBAL STARTUP AWARD HEALTH TECH STARTUP OF THE YEAR ",
    },
    { img: award3, text: "GLOBAL STARTUP AWARD STARTUP OF THE YEAR" },
    { img: award4, text: "Accredited CPD provider" },
    { img: logo, text: "Finalist in NINJA" },
    { img: award6, text: "Point of care ultrasound trauma - EFAST Course" },
    { img: award7, text: "First National Health Exhibition" },
    { img: award8, text: "The Children's Heart Fund of Ethiopia Recognition" },
    {
      img: award9,
      text: "International Training Site for American Heart Association",
    },
    { img: award10, text: "African Impact Challenge 2024 Recognition" },
  ];
  return (
    <div className="p-12">
      <div className="flex flex-col items-center p-3">
        <h1 className="md:text-3xl text-center">Awards And Recognition</h1>
        <div className="divider" />
      </div>
      <SimpleSlider images={awards} />
    </div>
  );
};

export default Awards;
