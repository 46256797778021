import img1 from "../../Assets/Research/photo_2025-02-11_09-37-10.jpg";
import img2 from "../../Assets/Research/photo_2025-02-11_09-37-59.jpg";
import img3 from "../../Assets/Research/photo_2025-02-11_09-38-10.jpg";
import img4 from "../../Assets/Newsimage/Finalist/photo_2025-02-20_10-49-40.jpg";
import img5 from "../../Assets/Newsimage/Finalist/photo_2025-02-20_10-51-39.jpg";
import img6 from "../../Assets/Newsimage/Finalist/photo_2025-02-20_10-51-45.jpg";
import img7 from '../../Assets/Newsimage/Digital transformation/photo_2025-02-20_11-03-38.jpg'
import img8 from '../../Assets/Newsimage/Digital transformation/photo_2025-02-20_11-03-57.jpg'
import img9 from '../../Assets/Newsimage/Digital transformation/photo_2025-02-20_11-04-00.jpg'
import img10 from '../../Assets/Newsimage/First Responder/photo_2025-02-20_10-59-07.jpg'
import img11 from '../../Assets/Newsimage/First Responder/photo_2025-02-20_10-59-25.jpg'
import img12 from '../../Assets/Newsimage/First Responder/photo_2025-02-20_10-59-29.jpg'

const News = [
  {
    id:0,
    image: [{ img: img1 }, { img: img2 }, { img: img3 }],
    Title:
      " Blue Health Ethiopia at the American College of Surgeons Clinical Congress",
    Date: "oct 23/ 2024",
    Desc:
      'We are proud to share that our Research Coordinator, Dr. Hilkiah Kinfemichael Suga, presented our study, "Effectiveness of First Aid Training for High School Students: An Experience from Ethiopia," at the American College of Surgeons Clinical Congress in San Francisco, CA, from October 19-22, 2024.' +
      "This research highlights the critical role of first aid education for young people, reinforcing our commitment to strengthening emergency response and life-saving skills across Ethiopia." +
      "Being part of this prestigious global platform allowed us to share valuable insights, exchange knowledge, and contribute to the conversation on enhancing first aid training worldwide." +
      "We are grateful for the opportunity to represent Ethiopia and advocate for a future where more lives can be saved through education and preparedness! " +
      'Our Research Coordinator, Hilkiah Kinfemichael Suga, MD, presented our study, "Effectiveness of First Aid Training for High School Students: An Experience from Ethiopia," at the American College of Surgeons Clinical Congress in San Francisco, CA, from October 19-22, 2024.' +
      "The research sheds light on the importance of equipping young people with life-saving skills, reflecting our dedication to enhancing emergency response across Ethiopia." +
      "Grateful for the opportunity to share our insights on this global stage.",
  },
  {
    id:1,
    image: [{ img: img4 }, { img: img5 }, { img: img6 }],
    Title:
      "Blue Health Ethiopia Named Finalist for the 2024 #WeAreTogether International Prize!",
    Date: "dec 9/2024",
    Desc:
      "We are honored to have been recognized as a finalist for the 2024 We Are Together International Prize in the Healthcare and Emergency Response category! This acknowledgment highlights our ongoing mission to equip Ethiopian communities with essential first aid skills, mobilize volunteers, and enhance emergency response capabilities." +
      "Our team had the privilege of participating in this year’s We Are Together International Prize award ceremony in Moscow, Russia, where we engaged with global changemakers dedicated to impactful humanitarian efforts. The event showcased inspiring initiatives from around the world, reinforcing the importance of collaboration and innovation in healthcare." +
      "We remain committed to advancing emergency care, empowering communities, and saving lives. Thank you to everyone who supports our mission—together, we are building a stronger, more resilient healthcare system! " +
      "We are thrilled to announce that Blue Health Ethiopia was named a finalist for the 2024 #WeAreTogether International Prize in the Healthcare and Emergency Response category! This recognition reflects our unwavering commitment to equipping communities across Ethiopia with essential first aid knowledge, engaging countless volunteers, and strengthening emergency response skills in diverse communities." +
      "Our team participated in this year’s We Are Together International Prize award, held in Moscow, Russia. This year’s ceremony celebrated the impactful work of numerous initiatives worldwide, highlighting the dedication and selflessness of individuals and groups committed to making a difference." +
      "Blue Health remains dedicated to improving emergency response capabilities and saving lives. Together, we are shaping the future of healthcare!",
  },
  {
    id:2,
    image: [{ img: img10 }, { img: img11 }, { img: img12 }],
    Title:
      "Empowering First Responders: 180 Traffic Police & Drivers Certified in First Aid!",
    Date: "aug 19/2024",
    Desc:
      "We are proud to have successfully certified 180 traffic police officers and country road drivers in First Aid and Basic Life Support (BLS) in collaboration with Kidy Foundation!" +
      "With road traffic accidents claiming at least 10 lives daily in Ethiopia, equipping those on the frontlines with life-saving skills is a crucial step toward reducing fatalities and improving emergency response." +
      "By empowering traffic police and drivers with the knowledge to act swiftly in critical situations, we are working towards safer roads and stronger communities. A huge thank you to Kidy Foundation for partnering with us in this important mission!" +
      "We're thrilled to announce that we have successfully certified 180 traffic police officers and country road drivers in first aid and basic life support, in collaboration with Kidy Foundation!" +
      "In Ethiopia, where at least 10 lives are lost daily due to road traffic accidents, this training is vital. Equipping our traffic police and drivers with these life-saving skills can make a significant difference in reducing fatalities on our roads. Together, we can help save lives and create safer roads for everyone. ",
  },
  {
    id:3,
    image: [{ img: img7 }, { img: img8 }, { img: img9 }],
    Title:
      "Blue Health Ethiopia at the Accelerated Digital Transformation Summit!",
    Date: "aug 24/2024",
    Desc:
      "Our CEO had the privilege of joining a panel of industry leaders at the Accelerated Digital Transformation Summit at Sheraton Addis, where they explored the sectoral impacts of ICT infrastructure, with a special focus on healthcare." +
      "The discussion highlighted the crucial role of digital transformation in enhancing healthcare delivery, improving access, and driving innovation in Ethiopia’s health sector. Engaging with experts and stakeholders provided valuable insights into the future of technology-driven healthcare solutions." +
      "We’re excited to be part of this digital revolution and look forward to contributing to a more connected, efficient, and impactful healthcare system." +
      "Our CEO joined a panel of industry leaders to discuss the Sectoral Impacts of ICT infrastructure, with a focus on Health, shaping the future of digital transformation at the Accelerated Digital Transformation Summit at Sheraton Addis Hotel. We are excited to be part of this summit!",
  },
];
export default News