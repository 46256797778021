import React from "react";
import logo from "../../Assets/Logo Transparent 1.png";
import { Link } from "react-scroll";
import { FaInstagram } from "react-icons/fa";
import { LiaTelegramPlane } from "react-icons/lia";
import { PiTiktokLogo } from "react-icons/pi";
import { FiFacebook, FiLinkedin, FiYoutube } from "react-icons/fi";
import bgimg from "../../Assets/image/5G7A0243.JPG";

const Footer = () => {
  const links = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];

  const icons = [
    {
      icon: FaInstagram,
      name: "Instagram",
      social: "https://www.instagram.com/bluehealthethiopia",
    },
    {
      icon: LiaTelegramPlane,
      name: "Telegram",
      social: "https://t.me/bluehealthethiopia",
    },
    {
      icon: PiTiktokLogo,
      name: "Tiktok",
      social: "https://www.tiktok.com/@bluehealthethiopia",
    },
    {
      icon: FiFacebook,
      name: "Facebook",
      social: "https://www.facebook.com/bluehealthethiopia",
    },
    {
      icon: FiLinkedin,
      name: "LinkedIn",
      social: "https://www.linkedin.com/company/blue-health-ethiopia",
    },
    {
      icon: FiYoutube,
      name: "YouTube",
      social: "https://www.youtube.com/@bluehealthethiopia",
    },
  ];

  return (
    <div className="relative flex flex-col justify-center items-center py-10 md:px-5 bg-black bg-opacity-75">
      <img src={bgimg} alt="" className="absolute w-full h-full left-0 object-cover object-center -z-30" />

      {/* Logo */}
      <img src={logo} alt="Company Logo" className="w-44 mb-6" />

      {/* Links & Socials */}
      <div className="flex flex-row md:flex-row justify-between md:justify-between w-full md:w-1/2  text-center md:text-left">
        {/* Links Section */} 
        <div className="p-3 flex flex-col justify-center md:items-start items-center">
          <h1 className="text-white font-semibold">Quick Links</h1>
          <div className="divider" />
          <div className="flex flex-col gap-2 text-white text-sm py-3">
            {links.map((item, index) => (
              <Link
                key={index}
                to={item.scroll}
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className="hover:text-secondary hover:scale-105 duration-200 cursor-pointer"
              >
                {item.link}
              </Link>
            ))}
          </div>
        </div>

        {/* Social Media Section */}
        <div className="p-3 flex flex-col justify-center md:items-start items-center">
          <h1 className="text-white font-semibold">Follow Us</h1>
          <div className="divider" />
          <div className="flex flex-col justify-center md:justify-start gap-4 text-white text-sm my-3">
            {icons.map((item, index) => (
              <a
                key={index}
                href={item.social}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 hover:scale-105 hover:opacity-80 duration-200"
              >
                <item.icon className="text-xl text-secondary" />
                <p>{item.name}</p>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="w-full max-w-5xl border-t border-gray-600 my-5" />

      {/* Copyright & Developer Info */}
      <div className="text-center text-white text-xs md:text-sm space-y-2">
        <p>&copy; {new Date().getFullYear()} Ethio Afro General Trading. All Rights Reserved.</p>
        <p>Developed by <span className="text-secondary font-semibold">Tilet Tech</span></p>
      </div>
    </div>
  );
};

export default Footer;
