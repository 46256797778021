import React, { useEffect } from "react";
import Imageslider from "../../../Component/News Detail/Imageslider";
import { useParams } from "react-router-dom";
import Description from "../../../Component/News Detail/Description";
import News from "../../../Data/News/NewsData";

const NewsDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  return (
    <div>
      <Imageslider
        img1={News[id].image[0].img}
        img2={News[id].image[1].img}
        img3={News[id].image[2].img}
      />
      <Description
        Title={News[id].Title}
        Date={News[id].Date}
        Desc={News[id].Desc}
      />
    </div>
  );
};

export default NewsDetails;
