import React from "react";
import Slider from "react-slick";

const Imageslider = ({ img1, img2, img3 }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Adjusted for better UX
    arrows: false, // Hide arrows by default
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // Tablets
        settings: {
          dots: true,
          arrows: false, // Hide arrows on tablets
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          dots: true,
          arrows: false, // Hide arrows on mobile
        },
      },
    ],
  };

  return (
    <div className="max-w-screen-lg mx-auto overflow-hidden">
      <Slider {...settings}>
        {[img1, img2, img3].map((img, index) => (
          <div key={index} className="w-full">
            <div className="relative flex items-center h-[20rem] md:h-[30rem] lg:h-[40rem] w-full">
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover object-center rounded-lg"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Imageslider;
