import React from "react";
import Slider from "react-slick";

const Slid = ({ newslide }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="overflow-hidden w-full">
      <Slider {...settings}>
        {newslide.map((items, index) => (
          <div key={index} className="w-full">
            <div className="relative flex flex-col md:flex-row justify-between items-center h-[20rem] md:h-[30rem] w-full">
              
              {/* Image Section */}
              <img
                src={items.image[1]?.img || ""}
                alt=""
                className="w-full md:w-2/3 h-full object-cover"
              />
              
              {/* Text Section */}
              <div className="p-4 md:px-6 bg-gray-100 h-full flex flex-col justify-center items-start gap-4 w-full md:w-1/3">
                <h1 className="text-xl md:text-3xl font-bold">{items.Title}</h1>
                <p className="text-sm md:text-base text-gray-700">
                  {items.Desc.slice(0, 150)}...
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slid;
