import React, { useEffect, useState } from "react";
import Slid from "../../../Component/News Feed/Slid";
import NewsCard from "../../../Component/News Feed/NewsCard";
import News from "../../../Data/News/NewsData";

const Mainpage = () => {
  const [visibleCount, setVisibleCount] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  return (
    <div className="w-full">
      <Slid newslide={(News).slice(0,3)} />
      
      <div className="px-4 sm:px-8 md:px-10 py-6 space-y-4">
        {/* Latest News Header */}
        <div className="">
          <h1 className="text-2xl font-bold">Latest News</h1>
          <div className="divider h-[2px] bg-gray-300 my-4"></div>
        </div>

        {/* News Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <NewsCard news={News.slice(0, visibleCount)} />
        </div>

        {/* Show More Button */}
        {visibleCount < News.length && (
          <div className="flex justify-center py-6">
            <button
              onClick={handleShowMore}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mainpage;
