import React, { useEffect } from 'react'
import Imageslide from '../../Component/ServiceDetail/Training/Imageslide'
import Descriptionsection from '../../Component/ServiceDetail/Training/Descriptionsection'
import ImpactSection from '../../Component/ServiceDetail/Training/ImpactSection'

const Training = () => {
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className=''>
      <Imageslide/>
      <Descriptionsection/>
      <ImpactSection/>
    </div>
  )
}

export default Training
