import React from "react";

const Descriptionsection = () => {
  const descriptionData = [
    {
      title: "Community Training Programs",
      description:
        "We equip individuals with essential skills to handle emergencies and improve safety in daily life. Our training is tailored for students, teachers, professionals in high-risk industries, and the general public.",
      items: [
        {
          title: "First Aid Training",
          content:
            "Learn to manage emergencies like bleeding, choking, burns, and fractures, with certification from the American Heart Association.",
        },
        {
          title: "Child Care Training",
          content:
            " Essential skills for caregivers to ensure children's safety and well-being.",
        },
        {
          title: "Elderly Care Training",
          content:
            " Training to provide compassionate and effective elderly care.",
        },
        {
          title: " Ergonomics Training",
          content:
            "Workplace safety tips to prevent injuries through proper posture and equipment use.",
        },
        {
          title: "Fire Safety Training",
          content:
            "Fire prevention, evacuation procedures, and fire extinguisher use.",
        },
      ],
    },
  ];

  const descriptionData2 = [
    {
      title: "CPD Training for Healthcare Professionals",
      description:
        "Blue Health Ethiopia offers accredited Continuous Professional Development (CPD) programs to help healthcare professionals maintain and enhance their skills.",
        items:[
            {content:'Accredited courses with Continuing Education Units (CEUs) for medical license renewal.'},
            {content:'Training in Basic Life Support (BLS), Advanced Cardiac Life Support (ACLS), Pediatric Advanced Life Support (PALS), and FAST (Focused Assessment with Sonography in Trauma).'},
            {content:'Courses in telemedicine, medical ethics, healthcare management, and provider behavior change.'}
        ]
    },
  ];
  return (
    <div className="flex flex-col md:flex-row justify-between items-center px-4 md:px-10 gap-5 py-10 ">
      {descriptionData.map((section, index) => (
        <div
          key={index}
          className="aspect-square bg-blue rounded-lg px-6 py-4 text-lg space-y-4 w-full md:w-1/2 flex flex-col justify-center items-center"
        >
          <h1 className="text-2xl font-bold text-black">{section.title}</h1>
          <p className="">{section.description}</p>
          <ul className="space-y-4">
            {section.items.map((item, idx) => (
              <li key={idx}>
                <span className="font-bold">{item.title}:</span> {item.content}
              </li>
            ))}
          </ul>
        </div>
      ))}
      {descriptionData2.map((section, index) => (
        <div
          key={index}
          className="aspect-square bg-blue rounded-lg px-6 py-4 text-lg space-y-4 w-full md:w-1/2 flex flex-col justify-center items-center"
        >
          <h1 className="text-2xl font-bold text-black">{section.title}</h1>
          <p className="">{section.description}</p>
          <ul className="space-y-4">
            {section.items.map((item, idx) => (
              <li key={idx}>
                <p> {item.content}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Descriptionsection;
