import React from "react";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll } from "react-scroll";
import LogoColor from "../../Assets/Logo Transparent 3.png";

const DesktopNav = ({ sticky }) => {
  const navigate = useNavigate();

  const Nav = [
    { link: "Home", scroll: "Home" },
    { link: "Services", scroll: "Services" },
    { link: "News Feed", scroll: "News" },
    { link: "About Us", scroll: "About" },
    { link: "Contact Us", scroll: "Contact" },
  ];

  const handleNavigation = (targetSection) => {
    if (window.location.pathname !== "/") {
      // Navigate to the main page first
      navigate("/");
      // Delay scrolling until the DOM has rendered
      setTimeout(() => scrollToSection(targetSection), 300); // Adjust delay if needed
    } else {
      scrollToSection(targetSection);
    }
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      animateScroll.scrollTo(element.offsetTop, {
        duration: 1000,
        smooth: true,
      });
    }
  };

  return (
    <div
      className={
        !sticky
          ? `md:flex justify-between items-center hidden bg-white  py-3  w-full z-50`
          : "fixed hidden w-full z-[11111] md:flex justify-between bg-white  items-center py-3 "
      }
    >
      <ScrollLink
        to="Home"
        spy={true}
        smooth={true}
        offset={-100}
        duration={1000}
      >
        <img
          src={LogoColor}
          alt="Logo"
          className="w-40 mx-12 cursor-pointer"
          onClick={() => handleNavigation("Home")}
        />
      </ScrollLink>
      <div className="text-sm space-x-7 bg-white p-4 right-0 blur-none">
        {Nav.map((items, index) => (
          <ScrollLink
            key={index}
            to={items.scroll}
            spy={true}
            smooth={true}
            offset={-100}
            duration={1000}
            onClick={() => handleNavigation(items.scroll)}
            className="hover:underline hover:text-secondary p-1 text-black duration-200 ease-in-out cursor-pointer"
          >
            {items.link}
          </ScrollLink>
        ))}
        <button className="bg-secondary px-4 py-2 rounded-lg font-semibold text-white hover:bg-black duration-150 ease-in-out">Verify Certificate</button>
      </div>
    </div>
  );
};

export default DesktopNav;
