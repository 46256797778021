import React from "react";

const DescCard = ({items,header}) => {

  return (
        <div className="z-50 p-5 flex flex-col justify-start bg-white shadow-2xl md:w-96 w-72 md:h-72 -mt-28 rounded-lg">
          <div
            className="flex flex-row justify-between items-center">
            <h1 className="md:text-2xl text-xl">{header}</h1>
            <items.icon className="md:text-3xl text-xl text-black" />
          </div>
          <div className="divider" />
          <p className="md:py-3 py-4 md:text-lg text-sm">
            {items.text}
          </p>
        </div>
  )
};

export default DescCard;
