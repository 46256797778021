import React from "react";

const DescConsult = () => {
  const list = [
    {
      title: "Industry Expertise:",
      desc: "Our team consists of healthcare professionals, safety experts, and public health consultants with extensive experience in workplace health management.",
    },
    {
      title: "Customized Solutions:",
      desc: "We tailor our services to meet the unique needs of each organization, ensuring optimal health outcomes.",
    },
    {
      title: "Regulatory Compliance:",
      desc: "Our strategies align with both Ethiopian and international health & safety standards to keep businesses compliant and risk-free.",
    },
    {
      title: "Proven Impact:",
      desc: "We have successfully partnered with various industries, helping businesses reduce absenteeism, increase productivity, and enhance overall workplace safety.",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center gap-6 px-4 md:px-10">
      {/* Consultation Header */}
      <div className="flex flex-col justify-center items-center py-6 text-center w-full md:w-3/4">
        <h1 className="text-3xl md:text-5xl font-bold text-black">
          Consultation
        </h1>
        <div className="w-20 h-1 bg-secondary my-3 rounded-full"></div>
        <p className="text-gray-700 text-base md:text-lg leading-relaxed">
          At Blue Health Ethiopia, we offer comprehensive health consultation
          services designed to support businesses in creating a healthier,
          safer, and more productive work environment. Our expertise spans
          across industries, providing organizations with tailored health
          strategies that enhance workplace well-being and safety while aligning
          with local regulations and global best practices.
        </p>
        <p className="text-gray-700 text-base md:text-lg leading-relaxed mt-3">
          Our consultation covers workplace health & wellness programs,
          occupational health & safety, emergency preparedness, first aid
          integration, and different health strategies, ensuring a healthier and
          more productive workforce. We partner with companies to develop
          sustainable health solutions that align with their organizational
          goals and industry standards.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="flex flex-col justify-center items-center text-center w-full md:w-3/4">
        <h1 className="text-2xl md:text-3xl font-bold text-black">
          Why Choose Us?
        </h1>
        <div className="w-20 h-1 bg-secondary my-3 rounded-full"></div>
        <ul className="text-left text-base md:text-lg space-y-4">
          {list.map((items, index) => (
            <li key={index} className="flex gap-2">
              <span>
                <span className="font-bold">{items.title} </span>
                {items.desc}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DescConsult;
