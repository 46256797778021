import React, { useEffect, useState } from "react";
import Slide from "../../../Component/Blog/Slide";
import BlogCard from "../../../Component/Blog/BlogCard";
import Blog from "../../../Data/Blog/BlogData";

const BlogMainpag = () => {
  const [visibleCount, setVisibleCount] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 10);
  };

  return (
    <div className="w-full">
      {/* Slide Section */}
      <Slide blogslide={Blog} />

      {/* Blog Section */}
      <div className="px-4 sm:px-6 md:px-12 py-8">
        <h1 className="text-xl sm:text-2xl font-bold">Latest News</h1>
        <div className="w-16 sm:w-20 h-1 bg-secondary my-2"></div>

        {/* Blog Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 md:gap-10 mt-6">
          <BlogCard Blog={Blog.slice(0, visibleCount)} />
        </div>

        {/* Show More Button */}
        {visibleCount < Blog.length && (
          <div className="flex justify-center mt-8">
            <button
              onClick={handleShowMore}
              className="px-6 py-2 text-white bg-secondary rounded-md transition-transform duration-300 hover:scale-95"
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogMainpag;
