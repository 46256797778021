import React from "react";
import { AiOutlineLinkedin } from "react-icons/ai";
import {Link} from "react-router-dom"
import adam from "../../../Assets/aboutusimg/Adam.JPG";
import bini from "../../../Assets/aboutusimg/bini.JPG";
import ell from "../../../Assets/aboutusimg/Ela.JPG";
import nathan from "../../../Assets/aboutusimg/nathan.png";
import kirbual from '../../../Assets/aboutusimg/Kirubel.JPG'
import Faisel from '../../../Assets/aboutusimg/Faisel.JPG'
import Harge from '../../../Assets/aboutusimg/Hareg.JPG'
import Tihitina from '../../../Assets/aboutusimg/Tihitina.JPG'
import Beti from '../../../Assets/aboutusimg/Beti.JPG'
import Hana from '../../../Assets/aboutusimg/Hana.JPG'
import Slider from 'react-slick'

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  const image = [
    {
      img: bini,
      Nametext: "Dr. Biniyam Alemu",
      position: "CEO and Co-founder",
      link: "https://www.linkedin.com/in/binyam-alemu-md-390575209",
    },
    {
      img: ell,
      Nametext: "Dr. Ellias Tadesse",
      position: "COO and Co-founder",
      link: "https://www.linkedin.com/in/elias-tadesse-md-48a700209",
    },
    {
      img: adam,
      Nametext: "Dr. Adam Getachew",
      position: "CTO and Co-founder",
      link: "https://www.linkedin.com/in/adam-getachew-4b5446151",
    },
    {
      img: nathan,
      Nametext: "Dr. Nathan Muluberhan",
      position: "CPD director and Co-founder",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
    {
      img: kirbual,
      Nametext: "Dr. Kirubel Shesew Mengistu",
      position: "Chief Programs Officer/ CPO",
      link: "https://www.linkedin.com/in/kirubel-shesew-md-9a3027268/",
    },
    {
      img: Faisel,
      Nametext: "Dr. Faisel Nesru",
      position: "Chief Training Coordinator",
      link: "https://www.linkedin.com/in/faisel-nesru-md-16b316268/",
    },
    {
      img: Harge,
      Nametext: "Ms. Hareg Alemu Haregu",
      position: "Digital Marketing Manager",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
    {
      img: Tihitina,
      Nametext: "Ms. Tihitina Kebede",
      position: "Junior Technology Officer",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
    {
      img: Beti,
      Nametext: "Ms. Betelhem umer",
      position: "Finance officer",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
    {
      img: Hana,
      Nametext: "Dr. Hana Serbesa Yadete",
      position: "Research Coordinator",
      link: "https://www.linkedin.com/in/dr-nathan-muluberhan-72b2a528",
    },
  ];
  return (
    <div id="About" className="flex flex-col  items-center py-6 bg-graywhite ">
      <div className="flex flex-col justify-center items-center">
        <h1 className="md:text-3xl">About Us</h1>
        <div className="divider" />
      </div>
      <div className="w-screen overflow-hidden">
        <Slider {...settings} className="md:p-10 p-6">
        {image.map((items,index) => (
         <a key={index} href={items.link} className="relative h-96 md:h-80 w-80 md:w-72 bg-gray-300  flex flex-col justify-center items-center transition-transform  hover:scale-95 duration-150 ease-in-out">
            <img src={items.img} alt="" className="absolute w-full h-full md:object-cover aspect-auto object-top grayscale hover:grayscale-0 duration-150 ease-in-out" />
            <div className="absolute bottom-0 text-start hover:grayscale-0 w-full px-3 py-3 rounded-lg text-white">
              <h1 className="text-lg">{items.Nametext}</h1>
              <p>{items.position}</p>
            </div>
         </a>
        ))}</Slider>
      </div>
    </div>
  );
};

export default AboutUs;
