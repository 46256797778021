import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Blog from "../../Data/Blog/BlogData"; // Importing Blog data

const Detailpage = () => {
  const { id } = useParams(); // Get ID from URL params
  const blogPost = Blog.find((post) => post.id === parseInt(id)); // Find the blog by ID

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!blogPost) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-2xl font-bold">Blog not found</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start justify-center px-12 py-5 gap-5">
      {/* Title & Author Section */}
      <div className="space-y-3">
        <h1 className="text-3xl font-bold">{blogPost.Title}</h1>
        <div className="flex gap-5 items-center">
          <img
            src={blogPost.profile}
            alt="Blog"
            className="rounded-full w-12 h-12 object-cover"
          />
          <div>
            <h1 className="text-base font-semibold">Blue health Ethiopia</h1>
          </div>
        </div>
      </div>

      {/* Blog Image Section */}
      <div className="w-full h-[500px]">
        <img
          src={blogPost.image}
          alt="Blog Cover"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>

      {/* Blog Description */}
      <div>
        <p className="text-lg text-gray-800 leading-relaxed">{blogPost.Desc}</p>
      </div>
    </div>
  );
};

export default Detailpage;
