import React from "react";
import { useNavigate } from "react-router-dom";
import NewsCard from "../../../Component/News Feed/NewsCard";
import img1 from "../../../Assets/image/Mentorship.jpg";
import News from '../../../Data/News/NewsData'

const NewsFeed = () => {
  const nav = useNavigate();
  
  // News Data
  const news = [
    {
      image: img1,
      headline: "This is the headline",
      text: "This is the news part of the news",
    },
    {
      image: img1,
      headline: "Another headline",
      text: "More details about this news article.",
    },
    {
      image: img1,
      headline: "Latest Update",
      text: "Stay updated with the latest news and events.",
    },
  ];

  // Click handler for navigating to the News page
  const handleClick = () => nav("/News");

  return (
    <div id="News" className="flex flex-col justify-start items-start gap-8 px-4 md:px-12 py-5">
      
      {/* Title Section */}
      <div className="space-y-3">
        <h1 className="relative text-3xl font-semibold">News & Events</h1>
        <div className="divider"></div>
      </div>

      {/* News Section */}
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-6 w-full overflow-hidden">
        <NewsCard news={(News).slice(0,3)} handleclick={handleClick} />
      </div>

      {/* Visit Page Button */}
      <div className="w-full flex justify-center">
        <button 
          onClick={() => nav("/News")} 
          className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-opacity-80 transition duration-300"
        >
          Visit News Page
        </button>
      </div>
      
    </div>
  );
};

export default NewsFeed;
