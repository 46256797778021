import img1 from "../../Assets/Blogimage/blog2/DALL·E_2025_02_14_00_55_27_A_young_woman_named_Arsema_performing.webp";
import img2 from "../../Assets/Blogimage/blog2/DALL·E_2025_02_14_00_58_09_An_Ethiopian_high_school_student_named.webp";
import img3 from "../../Assets/Blogimage/blog5/DALL·E_2025_02_14_01_02_12_A_realistic_and_educational_illustration.webp";
import profile from '../../Assets/Logo Transparent 3 copy.png'
const Blog = [
    {
      id: 0,
      image: require("../../Assets/Blogimage/blog2/DALL·E_2025_02_14_00_55_27_A_young_woman_named_Arsema_performing.webp"),
      profile: profile,
      Name: "Arsema",
      Title: "A Quick Action: Saving a Life",
      Desc: `While walking down the street, a man suddenly collapsed.
      Arsema's Testimony: Blue Health's winter trainee, Arsema, witnessed the incident but initially hesitated to intervene.
  
      "I tried to check if he would respond, but there was none. I told my friend to call an ambulance. Meanwhile, I started assessing the injured man and attempted to restore his breathing. However, he was not breathing at all. That’s when I began CPR."
  
      Arsema recalls the moment vividly.
      Those minutes felt like hours. The ambulance we called didn’t arrive, so we contacted a private one. More than 10 minutes had already passed by the time it arrived.
  
      I performed CPR for about 7–8 minutes. It was extremely exhausting. After four minutes, another person stepped in to assist me. But since they couldn’t apply proper pressure, I resumed after a short one-minute break.
  
      Finally, when the ambulance arrived, the collapsed man had started breathing again. Though his breath was still weak, he had regained it. He was then transported to the hospital. Arsema doesn’t know what happened afterward, but one thing is certain: her quick response gave someone a second chance at life.
  
      This story is just one of many that highlight the importance of first aid training.`,
    },
    {
      id: 1,
      profile: profile,
      image: require("../../Assets/Blogimage/blog2/DALL·E_2025_02_14_00_58_09_An_Ethiopian_high_school_student_named.webp"),
      Name: "Mekdelawit",
      Title: "A High School Hero: Saving Lives with First Aid",
      Desc: `When Mekdelawit, a high school student, attended first aid training with Blue Health Ethiopia, she never thought she’d find herself in a real-life emergency. She took the training hoping to help others someday but didn’t expect that day to come so soon.
  
      One afternoon, while walking down the street, she saw a crowd gathered around an unconscious person. Curious and concerned, she stepped forward to check what was happening. To her shock, the person had no pulse.
  
      Although nervous and unsure, she remembered her training. She asked those around her to call an ambulance and hesitantly started CPR. It was her first time performing CPR on a real person—not just on a training doll—and self-doubt crept in with every compression.
  
      But after just 40 compressions, the pulse returned. She had saved a life. "I couldn’t believe it," she said later. "The training gave me the knowledge, but I never imagined I’d actually save someone’s life."
  
      Now, she is proof that first aid training doesn’t just teach skills—it empowers people to take action when every second counts.
  
      Imagine if she hadn’t been there that day. Blue Health Ethiopia isn’t just teaching first aid; it’s saving lives through lives.`,
    },
    {
      id: 2,
      profile: profile,
      image: require("../../Assets/Blogimage/blog5/DALL·E_2025_02_14_01_02_12_A_realistic_and_educational_illustration.webp"),
      Name: "",
      Title: "Earthquake",
      Desc: `Things we should do before an earthquake, during an earthquake, and after an earthquake.
  
      **1. Before an Earthquake (Get Ready!)**
      - Secure your home: Fix heavy furniture like shelves and TVs to the wall so they don’t fall.
      - Find safe spots: Know where to hide if shaking starts—like under a strong table or next to a sturdy wall.
      - Pack an emergency bag: Include water, flashlight, batteries, and first aid. Keep it in an easy-to-reach place.
      - Practice: Teach your family the “Drop, Cover, and Hold On” drill.
  
      **2. During an Earthquake (Stay Safe!)**
      **Dos**
      - If you’re inside:
        - Drop to the ground.
        - Cover your head and neck under a table or near a strong wall.
        - Hold on until the shaking stops.
      - If you’re outside: Move away from buildings, trees, or power lines. Stay in an open area.
      - If you’re in a car: Pull over to a safe spot, away from bridges or tall buildings, and stay inside.
  
      **Don’ts**
      - Don’t try to escape from inside to outside or vice versa; stay where you are.
      - Don’t stand near windows and glasses.
      - Don’t use elevators.
  
      **3. After an Earthquake (Stay Alert!)**
      - Check yourself and others: Look for injuries and help if you can.
      - Stay outside damaged buildings: They might collapse during aftershocks.
      - Listen for updates: Use your phone, radio, or other devices to get news and safety instructions.
      - Be ready for aftershocks: These are smaller quakes that can happen after the main one.`,
    },
  ];
  
export default Blog