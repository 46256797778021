import React from "react";
import BlogCard from "../../../Component/Blog/BlogCard";
import { useNavigate } from "react-router-dom";
import Blogs from "../../../Data/Blog/BlogData";

const Blog = () => {
  const nav = useNavigate();

  // Sample Blog Data
  const blog = [
    {
      image: "https://via.placeholder.com/400", 
      headline: "How to Improve Mental Health",
      text: "Discover the best ways to improve mental well-being and reduce stress.",
    },
    {
      image: "https://via.placeholder.com/400",
      headline: "Healthy Eating Habits",
      text: "A guide to balanced nutrition and how it affects overall health.",
    },
    {
      image: "https://via.placeholder.com/400",
      headline: "Benefits of Regular Exercise",
      text: "Why staying active is crucial for both physical and mental health.",
    },
  ];

  // Navigation function
  const handleClick = () => {
    nav("/blog");
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 md:px-12 gap-6 py-10">
      
      {/* Title Section */}
      <div className="flex flex-col items-center md:items-end w-full space-y-3">
        <h1 className="text-3xl font-semibold">Blue Health Blog</h1>
        <div className="divider"></div>
      </div>

      {/* Blog Cards Section */}
      <div className="flex flex-col md:flex-row  justify-between items-center w-full gap-6">
        <BlogCard Blog={Blogs}  />
      </div>

      {/* Visit Blog Page Button */}
      <div className="w-full flex justify-center mt-6">
        <button 
          onClick={()=>nav("/blog")} 
          className="bg-primary text-white font-semibold py-2 px-6 rounded-lg hover:bg-opacity-80 transition duration-300"
        >
          Visit Blog Page
        </button>
      </div>

    </div>
  );
};

export default Blog;
