import React from "react";
import SimpleSlider from "../../../Containers/SimpleSlider";
import logo1 from "../../../Assets/Client/1. MIDROC LOGO.jpg";
import logo2 from "../../../Assets/Client/10. Haile minas academy logo.jpg";
import logo3 from "../../../Assets/Client/11. Rohobot home care service logo.jpg";
import logo4 from "../../../Assets/Client/12. Pelican trading plc.png";
import logo5 from "../../../Assets/Client/13. Washangtion health service logo.jpg";
import logo6 from "../../../Assets/Client/14. We care logo.png";
import logo7 from "../../../Assets/Client/15. Bright acadamy logo.jpg";
import logo8 from "../../../Assets/Client/16. Maldo IM clinic.jpg";
import logo9 from "../../../Assets/Client/17. Flawless Events logo.jpg";
import logo10 from "../../../Assets/Client/2. Total energies logo.jpg";
import logo11 from "../../../Assets/Client/3. Holland Dairy Manufacturing loggg.jpg";
import logo12 from "../../../Assets/Client/4. Eshi Delivery logo.png";
import logo13 from "../../../Assets/Client/5. Addis Ketema Industrial College logo.jpg";
import logo14 from "../../../Assets/Client/6. Miluye foundation logo.jpg";
import logo15 from "../../../Assets/Client/7. Hayat medical collage logo.jpg";
import logo16 from "../../../Assets/Client/8. Sante Medical College logo.jpg";
import logo17 from "../../../Assets/Client/9. Waryt furniture logo.jpg";
import { img } from "framer-motion/client";

const Clients = () => {
  const images = [
    { img: logo1 },
    { img: logo2 },
    { img: logo3 },
    { img: logo4 },
    { img: logo5 },
    { img: logo6 },
    { img: logo7 },
    { img: logo8 },
    { img: logo9 },
    { img: logo10 },
    { img: logo11 },
    { img: logo12 },
    { img: logo13 },
    { img: logo14 },
    { img: logo15 },
    { img: logo16 },
    { img: logo17 },
  ];
  return (
    <div className="md:p-12 p-4">
      <div className="flex flex-col justify-center items-center pb-4">
        <h1 className="md:text-3xl">Our Clients</h1>
        <div className="divider" />
      </div>
      <SimpleSlider images={images} />
    </div>
  );
};

export default Clients;
