import React, { useEffect, useState } from "react";
// import Blog from "../Pages/Static Pages/Blog";
import DesktopNav from "../Component/Navigation/DesktopNav";
import Loader from "../Containers/Loader";
import AboutUs from "../Pages/Static Pages/Home/AboutUs";
import ContactUs from "../Pages/Static Pages/Home/ContactUs";
import OurPartner from "../Pages/Static Pages/Home/OurPartner";
import Home from "../Pages/Static Pages/Home/Home";
import Footer from "../Pages/Static Pages/Footer";
import NewsFeed from "../Pages/Static Pages/Home/NewsFeed";
import Services from "../Pages/Static Pages/Home/Services";
import CPD from "../Pages/Static Pages/Home/CPD";
import Awards from "../Pages/Static Pages/Home/Awards";
import BlueDesc from "../Pages/Static Pages/Home/BlueDesc";
import useSticky from "../Containers/UseSticky";
import PhoneNav from "../Component/Navigation/PhoneNav";
import Clients from "../Pages/Static Pages/Home/Clients";
import Blog from "../Pages/Static Pages/Home/Blog";

function ScrollRoute() {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setLoading] = useState(true); 
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* loading component rendered */}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-hidden ">
          <Home />
          <BlueDesc />
          <div className="space-y-10">
            <Services />
            <CPD />
            <NewsFeed />
            <Blog/>
            <Awards />
            <AboutUs />
            <OurPartner />
            <Clients/>     
          </div>
        </div>
      )}
    </>
  );
}

export default ScrollRoute;
