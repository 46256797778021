import React from "react";
import { useNavigate } from "react-router-dom";

const CPD = () => {
  const nav = useNavigate()
  return (
    <div className="relative bg-primary bg-center ">
      {/* Overlay */}

      <div className="relative z-10 flex flex-col items-center text-white justify-center px-6 py-12">
        {/* Title */}
        <h1 className="text-4xl md:text-5xl font-bold text-center">
          Blue Health CPD
        </h1>
        <div className="divider" />

        {/* Description */}
        <p className="text-sm md:text-lg text-center max-w-4xl leading-relaxed pt-5">
          Blue Health CPD is a web-based Learning Management System (LMS)
          designed for Ethiopian healthcare professionals. It offers flexible,
          accredited courses that fit busy schedules, allowing users to learn
          anytime, anywhere via computer or smartphone. With self-paced modules,
          virtual seminars, and interactive assessments, professionals earn
          Continuing Education Units (CEUs) for license renewal while staying
          updated on medical advancements. By enhancing expertise, Blue Health
          CPD strengthens Ethiopia’s healthcare system and improves patient
          care.
        </p>

        {/* CTA Button */}
        <a href='https://cpd.bluehealthethiopia.com/'  className="mt-8 px-6 py-3 text-sm md:text-base font-semibold bg-secondary text-black rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg transition duration-300">
          Register Now
        </a>
      </div>
    </div>
  );
};

export default CPD;
