import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";

const ModernSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="w-full px-4 py-10">
      <Slider {...settings} className="">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className="w-full flex flex-col items-center gap-4 py-5 px-2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 200, damping: 10 }}
          >
            {/* Image Container */}
            <div className="w-full aspect-video overflow-hidden rounded-lg ">
              <motion.img
                src={image.img}
                alt={image.text || `Slide ${index + 1}`}
                className="w-full h-full object-contain transition-transform duration-500 hover:scale-105"
              />
            </div>
            {/* Caption */}
            <p className="text-center text-gray-700 font-medium text-lg">
              {image.text}
            </p>
          </motion.div>
        ))}
      </Slider>
    </div>
  );
};

export default ModernSlider;
