import React from "react";

const Description = ({ Title,Date,Desc }) => {
  return (
    <div className="px-4 md:px-16 py-9 flex flex-col items-start gap-8">
     
        <div  className="space-y-4">
          {/* Title and Date */}
          <div>
            <h1 className="text-2xl md:text-3xl font-bold">{Title}</h1>
            <p className="text-gray-500">{Date}</p>
          </div>

          {/* Description */}
          <p className="text-lg leading-relaxed text-justify">
            {Desc}
          </p>
        </div>
     
    </div>
  );
};

export default Description;
