import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ Blog }) => {
  const nav = useNavigate();
  
  return (
    <>
      {Blog.map((items, index) => {
        const maxLength = 100;
        const truncatedDesc =
          items.Desc.length > maxLength
            ? items.Desc.slice(0, maxLength).split(" ").slice(0, -1).join(" ") + "..."
            : items.Desc;

        return (
          <div
            key={index}
            onClick={() => nav(`/blogdetail/${index}`)}
            className="relative text-white flex flex-col px-3 py-3 bg-primary justify-start items-start md:w-96 gap-3 bg-opacity-70 drop-shadow-2xl rounded-lg transition-transform duration-300 hover:scale-95"
          >
            <div className="">
              <img src={items.image} alt="" className="w-full rounded-lg" />
            </div>{" "}
            <div className="space-y-3 text-white -bottom-36 rounded-lg">
              <h1 className="text-lg">{items.Title}</h1>
              <p>{truncatedDesc}</p>
            </div>
            <div className="flex gap-5 items-center">
          <img
            src={items.profile}
            alt="Blog"
            className="rounded-full w-12 h-12 object-cover"
          />
          <div>
            <h1 className="text-base font-semibold">Blue health Ethiopia</h1>
          </div>
        </div>
          </div>
        );
      })}
    </>
  );
};

export default BlogCard;
