import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdSubject, MdOutlineEmail, MdError } from "react-icons/md";
import { PiChatTeardropTextBold } from "react-icons/pi";

const Email = () => {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_frrzi8u",
        "template_5e4xh1p",
        form.current,
        "JxwPHm-iXdbJRVzSv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail("");
          setSubject("");
          setText("");
          toast("Email is sent Successfully!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: AiFillCheckCircle,
            theme: "light",
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("Email was not sent!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: MdError,
            theme: "light",
          });
        }
      );
  };
  return (
    <div className=" right-0 z-50 p-4 w-fit md:mx-16 bg-graywhite mb-5 md:mt-20">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col justify-center items-center space-y-3">
        <div className="flex flex-row items-center md:w-80 w-72 bg-white p-1 space-x-2">
          {" "}
          <MdOutlineEmail className="text-xl text-gray" />
          <input
            type="email"
            name="user-Email"
            id=""
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Email Address"
            className="w-full outline-none border-none"
            required
          />
        </div>
        <div className="flex flex-row items-center md:w-80 w-72 bg-white p-1 space-x-2">
          {" "}
          <MdSubject className="text-xl text-gray" />
          <input
            type="text"
            name="from_name"
            id=""
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            placeholder="Subject"
            className="w-full outline-none border-none"
          />
        </div>
        <div className="flex flex-row items-start md:w-80 w-72 bg-white p-1 space-x-2">
          {" "}
          <PiChatTeardropTextBold className="text-xl text-gray" />
          <textarea
            name="message"
            id=""
            cols="30"
            rows="10"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            placeholder="Write Your Message Here..."
            required
            className="w-full outline-none"></textarea>
        </div>
        <button
          type="submit"
          className="bg-primary p-1 md:w-80 w-72 font-bold text-white hover:bg-secondary duration-200 ease-in-out">
          SEND
        </button>
        <ToastContainer />
      </form>
    </div>
  );
};

export default Email;
