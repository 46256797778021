import { Route, Routes } from "react-router-dom";
import ScrollRoute from "./Router/ScrollRoute";
import Mainpage from "./Pages/Dynamic pages/NewsPage/NewsMainpage";
import DesktopNav from "./Component/Navigation/DesktopNav";
import PhoneNav from "./Component/Navigation/PhoneNav";
import useSticky from "./Containers/UseSticky";
import { useState } from "react";
import Footer from "./Pages/Static Pages/Footer";
import ContactUs from "./Pages/Static Pages/Home/ContactUs";
import NewsDetails from "./Pages/Dynamic pages/NewsPage/NewsDetails";
import DigitalHealth from "./Pages/Static Pages/DigitalHealth";
import Training from "./Pages/Static Pages/Training";
import Research from "./Pages/Static Pages/Research";
import BlogMainpag from "./Pages/Dynamic pages/BlogPage/BlogMainpag";
import Consultation from "./Pages/Static Pages/Consultation";
import Detailpage from "./Component/Blog Detail/Detailpage";

function App() {
  // The holding state for the loading component
  const isSticky = useSticky(500);
  return (
    <div>
      {" "}
      <DesktopNav sticky={isSticky} />
      <PhoneNav sticky={isSticky} />
      <Routes>
        <Route path="/" element={<ScrollRoute />} />
        <Route path="/News" element={<Mainpage />} />
        <Route path="/newsdetails/:id" element={<NewsDetails />} />
        <Route path="/blogdetail/:id" element={<Detailpage />} />
        <Route path="/blog" element={<BlogMainpag />} />
        <Route path="/digitalhealth" element={<DigitalHealth />} />
        <Route path="/training" element={<Training />} />
        <Route path="/research" element={<Research />} />
        <Route path="/consultation" element={<Consultation/>}/>
      </Routes>
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
